<template>
  <div>
    <FilterHeader
      :show-del-btn="showDelBtn"
      @deleteTableData="deleteTableData"
    />
    <TableHeader
      :title="'联系人管理'"
      :icon="'el-icon-phone'"
      @insertCustomer="insertCustomer"
    >
      <div slot="dialog">
        <el-form label-position="right" :model="contactData">
          <el-form-item
            v-for="item in columnConfig"
            :key="item.prop"
            :label="item.label"
            label-width="110px"
            required
            v-show="item.show !== false"
          >
            <el-input v-model="contactData[item.prop]" />
          </el-form-item>
        </el-form>
      </div>
    </TableHeader>
    <Table
      :column-config="columnConfig"
      :table-data="contact_store.contact_info"
      @getDelData="getDelData"
      @editData="editData"
      @updateData="updateData"
    >
      <div slot="dialog">
        <el-form label-position="right" :model="rowData">
          <el-form-item
            v-for="item in columnConfig"
            :key="item.prop"
            :label="item.label"
            label-width="110px"
            required
            v-show="item.show !== false"
          >
            <el-input v-model="rowData[item.prop]" />
          </el-form-item>
        </el-form>
      </div>
    </Table>
  </div>
</template>

<script>
import FilterHeader from '../common/FilterHeader';
import TableHeader from '../common/TableHeader';
import Table from '../common/Table';
import { mapState } from 'vuex';
import {
  DELETE_CONTACT_INFO,
  GET_CONTACT_INFO,
  INSERT_CONTACT_INFO,
  UPDATE_CONTACT_INFO,
} from '../../store/type';
import { showMsg } from '../../util/msg';
import { timeStamp2String } from '../../util/timeStampConvert';

export default {
  name: 'ContactTable',
  components: {
    FilterHeader,
    Table,
    TableHeader,
  },
  data() {
    return {
      columnConfig: [
        { prop: 'name', label: '联系人姓名' },
        { prop: 'telephone', label: '座机号码' },
        { prop: 'email', label: '邮箱地址' },
        { prop: 'position', label: '职位' },
        { prop: 'sex', label: '性别' },
        { prop: 'address', label: '地址' },
        { prop: 'nextContactTime', label: '下次联系时间' },
        { prop: 'remark', label: '备注' },
      ],
      showDelBtn: false,
      delData: [],
      contactData: {},
      rowData: {},
    };
  },
  methods: {
    formStaffInfo(data) {
      return {
        name: data.name,
        telephone: data.telephone,
        email: data.email,
        position: data.position,
        sex: data.sex,
        address: data.address,
        nextContactTime: timeStamp2String(data.nextContactTime),
        remark: data.remark,
        id: data.id,
      };
    },
    deleteTableData() {
      this.$store.dispatch(DELETE_CONTACT_INFO, this.delData);
      showMsg('success', '删除成功！');
    },
    insertCustomer() {
      this.$store.dispatch(
        INSERT_CONTACT_INFO,
        this.formStaffInfo(this.contactData),
      );
      showMsg('success', '插入成功！');
    },
    updateData() {
      this.$store.dispatch(
        UPDATE_CONTACT_INFO,
        this.formStaffInfo(this.rowData),
      );
      showMsg('success', '更新成功！');
    },
    getDelData(val) {
      this.showDelBtn = val.length > 0;
      this.delData = val;
    },
    editData(data) {
      this.rowData = data;
    },
  },
  computed: {
    ...mapState({
      contact_store: state => state.contact_store,
    }),
  },
  created() {
    this.$store.dispatch(GET_CONTACT_INFO);
  },
};
</script>
