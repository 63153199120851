<template>
  <Pagination />
</template>

<script>
import Pagination from '../common/Pagination';
export default {
  name: 'ContactPagination',
  components: {
    Pagination,
  },
};
</script>
