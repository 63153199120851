<template>
  <div style="padding-top: 5px">
    <ContactTable />
    <ContactPagination />
  </div>
</template>

<script>
import ContactTable from '../contact/ContactTable';
import ContactPagination from '../contact/ContactPagination';
export default {
  name: 'ContactContainer',
  components: { ContactPagination, ContactTable },
};
</script>
